<template>
  <div class="pop-mina-con">
    <img :src="require('@/assets/images/tanq.png')" alt="" />
    <span class="title">质量安全快速检测</span>
    <div class="basic-info-con">
      <div class="bas-l">
        <img :src="require('@/assets/images/btzs.png')" alt="" />
        <span>基础数据展示</span>
      </div>
    </div>
    <div class="detection-con">
      <div class="top-con">
        <div class="top-left">
          <div class="c-mian-con cpjcs">
            <img :src="require('@/assets/images/cpsjc.png')" alt="" />
            <div class="bas-r">
              <div
                v-for="item in optionList"
                :key="item.id"
                @click="switchHand(item)"
                class="option-list"
              >
                <img
                  v-if="item.check"
                  :src="require('@/assets/images/wxz.png')"
                  alt=""
                />
                <img v-else :src="require('@/assets/images/xz.png')" alt="" />
                <span>{{ item.name }}</span>
              </div>
            </div>
            <progress-data
              v-if="dashboardShow"
              :dashboardList="dashboardList"
            />
          </div>
        </div>
        <div class="top-ri">
          <div class="c-mian-con">
            <img :src="require('@/assets/images/sshz.png')" alt="" />
            <!-- <div style="position: relative">
            
              <span
                style="
                  position: absolute;
                  top: 0px;
                  left: 41px;
                  font-size: 15px;
                  color: #f7fefe;
                "
                >缸豆专项检测</span
              >
            </div> -->

            <div class="qydys-list">
              <div class="title-con">
                <span>地区</span>
                <span>市场抽检</span>
                <span>基地抽检</span>
                <span>抽检总数</span>
              </div>

              <div v-if="summaryList.length > 0" class="news-list">
                <div
                  v-for="(item, idx) in summaryList"
                  :key="idx"
                  class="info-row"
                >
                  <span>{{ item.regionName }}</span>
                  <span>{{ item.lscj }}</span>
                  <span>{{ item.jgcj }}</span>
                  <span>{{ item.zh }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-con">
        <div class="c-mian-con">
          <img :src="require('@/assets/images/sjqs.png')" alt="" />
          <div class="tabs">
            <a-radio-group
              v-model="size"
              @change="sizeChange"
              style="margin-bottom: 16px"
            >
              <a-radio-button value="small"> 检测数 </a-radio-button>
              <a-radio-button value="default"> 合格率 </a-radio-button>
            </a-radio-group>
          </div>
          <!-- <div class="type-list">
            <div class="category">
              <h1 class="lbo-bar"></h1>
              <span>市场抽检合格率</span>
            </div>
            <div class="category">
              <h1 class="lbt-bar"></h1>
              <span>基地抽检合格率</span>
            </div>
            <div class="category">
              <img :src="require('@/assets/images/lbo.png')" alt="" />
              <span>市场抽检</span>
            </div>
            <div class="category">
              <img :src="require('@/assets/images/lbt.png')" alt="" />
              <span>基地抽检</span>
            </div>
          </div> -->
        </div>
        <div class="chart-con">
          <div class="line-con" id="lines"></div>
        </div>
      </div>
    </div>
    <div @click="closeHand" class="close">
      <img :src="require('@/assets/images/guanbi.png')" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import overlayApi from "@/request/api/overlay.js";
import dict from "@/mixins/index/index.js";
import progressData from "@/components/progress-jc/index.vue";
import * as echarts from "echarts";
import moment from "moment";
// import { BigNumber } from "bignumber.js";
export default {
  props: {
    productName: String,
    regionCode: String,
    regionLevel: Number,
    productNameList: Array,
  },
  components: {
    progressData,
  },
  data() {
    return {
      loading: false,
      ispictureshow: true,
      region: "",
      optionList: [
        {
          check: false,
          name: "本月",
          id: 1,
        },
        {
          check: false,
          name: "本周",
          id: 2,
        },
        {
          check: true,
          name: "昨日",
          id: 3,
        },
      ],
      hgzmrdysl: [],
      beganTime: "",
      endTime: "",
      summaryList: [],
      recSwiperOptionhz: {
        initialSlide: 0,
        slidesPerView: 5,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperhz: true,
      dashboardShow: false,
      dashboardList: [],
      timersetIntervalz: 0,
      size: "small",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.endTime = moment().subtract(1, "day").format("YYYY-MM-DD");
    this.beganTime = moment(this.endTime).format("YYYY-MM-DD");
    this.getpatrolDetection();
    this.getrealTimeDetection();
    this.getdetectionDayCount();
  },
  mixins: [dict],
  methods: {
    //查询
    switchHand(item) {
      this.optionList.forEach((element) => {
        if (element.check) {
          element.check = false;
        }
      });
      item.check = true;
      this.beganTime = "";
      this.endTime = "";
      if (item.id == 1) {
        //本月
        let start = moment().startOf("month").format("YYYY-MM-DD");
        let end = moment().endOf("month").format("YYYY-MM-DD");
        this.beganTime = start;
        this.endTime = end;
        this.getpatrolDetection();
      } else if (item.id == 2) {
        //本周
        let start = moment().startOf("week").format("YYYY-MM-DD");
        let end = moment().endOf("week").format("YYYY-MM-DD");
        this.beganTime = start;
        this.endTime = end;
        this.getpatrolDetection();
      } else if (item.id == 3) {
        this.endTime = moment().subtract(1, "day").format("YYYY-MM-DD");
        this.beganTime = moment(this.endTime).format("YYYY-MM-DD");
        this.getpatrolDetection();
      }
    },
    closeHand() {
      this.$emit("close");
    },
    //获取数据趋势
    async getdetectionDayCount() {
      //时间默认一个月
      let endTime = moment().format("YYYY-MM-DD");
      let beganTime = moment(endTime).subtract(30, "days").format("YYYY-MM-DD");
      let query = {
        beganTime: `${beganTime} 00:00:00`,
        endTime: `${endTime} 23:59:59`,
        region: this.regionCode,
        regionLevel: this.regionLevel,
      };
      try {
        let response = await overlayApi.detectionDayCount(query);
        let list = response.data;
        this.hgzmrdysl = list.filter((item) => {
          return item.tradeType == "基地抽检" || item.tradeType == "市场抽检";
        });
        await this.$nextTick();
        this.lineChart();
      } catch (error) {
        console.log(error);
      }
    },
    sizeChange(e) {
      let d = e.target.value;
      this.size = d;

      this.lineChart();
    },
    //数据趋势
    lineChart() {
      let _this = this;
      var chartDom = document.getElementById("lines");
      var myChart = echarts.init(chartDom);
      let list = this.hgzmrdysl;
      let createDates = [];
      let seriesline = [];
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        let printCount = element.printDayNumVos.map((item) => {
          return item.printCount;
        });
        let passRate = element.printDayNumVos.map((item) => {
          return (item.passRate * 100).toFixed(2);
        });
        let tempCreateDate = element.printDayNumVos.map((item) => {
          return item.createDate.substring(item.createDate.length - 5);
        });
        let ps = [];
        if (this.size == "small") {
          ps = printCount;
        } else {
          ps = passRate;
        }
        createDates = tempCreateDate;
        let item = {
          name: element.tradeType,
          data: ps,
          type: "line",
          itemStyle: {
            normal: { color: "" },
          },
        };
        let color = "#EF9F14";
        if (index == 0) {
          color = "#EF9F14";
        } else if (index == 1) {
          color = "#19AEFB";
        }
        item.itemStyle.normal.color = color;
        seriesline.push(item);
      }
      let name = "";
      if (this.size == "small") {
        name = "检测数";
      } else {
        name = "合格率";
      }
      var option = {
        tooltip: {
          trigger: "item",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "2%",
          right: "4%",
          bottom: "14%",
          top: "10%",
          containLabel: true,
        },
        legend: {
          data: ["市场抽检", "基地抽检"],
          left: "7%",
          top: "0%",
          textStyle: {
            color: "#fff",
          },
          itemWidth: 15,
          itemHeight: 10,
          itemGap: 25,
        },
        xAxis: {
          type: "category",
          data: createDates,
          axisLine: {
            lineStyle: {
              color: "#cdd5e2",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },

        yAxis: [
          {
            type: "value",
            name: name,
            nameTextStyle: {
              color: "#fff",
            },
            position: "left",
            axisLine: {
              lineStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              // formatter: "{value} %", //右侧Y轴文字显示
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        series: seriesline,
      };
      myChart.setOption(option);
      // 自动循环高亮点的代码
      var currentIndex = -1;
      function highlightNextPoint() {
        var dataLen = createDates.length;

        // 取消上一个点的高亮
        // myChart.dispatchAction({
        //   type: "downplay",
        //   seriesIndex: 0,
        //   dataIndex: currentIndex,
        // });

        currentIndex = (currentIndex + 1) % dataLen;

        // 高亮当前点
        // 显示 tooltip
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: currentIndex,
        });
      }
      if (_this.timersetIntervalz != 0) {
        clearInterval(_this.timersetIntervalz);
      }
      // 设置定时器来循环高亮点
      _this.timersetIntervalz = setInterval(highlightNextPoint, 2000);
    },
    //获取产品检测数
    async getpatrolDetection() {
      let query = {
        region: this.regionCode,
        regionLevel: this.regionLevel,
        beganTime: `${this.beganTime} 00:00:00`,
        endTime: `${this.endTime} 23:59:59`,
      };
      try {
        let response = await overlayApi.patrolDetection(query);
        let list = response.data;
        let total = 0;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          if (index == 0) {
            this.$set(element, "from", "#F0833A");
            this.$set(element, "to", "#F0833A");
          } else if (index == 1) {
            this.$set(element, "from", "#24E298");
            this.$set(element, "to", "#24E298");
          } else if (index == 2) {
            this.$set(element, "from", "#2485E2");
            this.$set(element, "to", "#2485E2");
          } else if (index == 3) {
            this.$set(element, "from", "#02C3DA");
            this.$set(element, "to", "#02C3DA");
          } else if (index == 4) {
            this.$set(element, "from", "#0082FF");
            this.$set(element, "to", "#007FFA");
          } else if (index == 5) {
            this.$set(element, "from", "#C0E224");
            this.$set(element, "to", "#C0E224");
          } else if (index == 6) {
            this.$set(element, "from", "#2485E2");
            this.$set(element, "to", "#2485E2");
          } else if (index == 7) {
            this.$set(element, "from", "#E25E24");
            this.$set(element, "to", "#D75A23");
          } else if (index == 8) {
            this.$set(element, "from", "#BFF03A");
            this.$set(element, "to", "#BDEE3A");
          } else if (index == 9) {
            this.$set(element, "from", "#1F29F1");
            this.$set(element, "to", "#1F29EE");
          } else if (index == 10) {
            this.$set(element, "from", "#5524E2");
            this.$set(element, "to", "#5323DC");
          } else if (index == 11) {
            this.$set(element, "from", "#02C3DA");
            this.$set(element, "to", "#02C3DA");
          } else if (index == 12) {
            this.$set(element, "from", "#0082FF");
            this.$set(element, "to", "#007FFA");
          } else if (index == 13) {
            this.$set(element, "from", "#20C089");
            this.$set(element, "to", "#1DA97F");
          } else if (index == 14) {
            this.$set(element, "from", "#2485E2");
            this.$set(element, "to", "#2485E2");
          } else if (index == 15) {
            this.$set(element, "from", "#B9E93A");
            this.$set(element, "to", "#B7E63A");
          }
          total += element.checkNum;
        }
        let dashboardList = list.map((item) => {
          let percent = (item.checkNumRate * 100).toFixed(2);
          percent = parseInt(percent);
          item.passRate = item.passRate * 100;
          return {
            percent: percent,
            name: item.productName,
            value: percent,
            from: item.from,
            to: item.to,
            passRate: item.passRate,
          };
        });

        this.dashboardList = dashboardList;
        this.dashboardShow = true;
      } catch (error) {
        console.log(error);
      }
    },
    //实时汇总
    async getrealTimeDetection() {
      let query = {
        region: this.regionCode,
        regionLevel: this.regionLevel,
      };
      try {
        let response = await overlayApi.realTimeDetection(query);
        let list = response.data;
        this.summaryList = list.map((item) => {
          let checkTypeAndNumList = item.checkTypeAndNumList.filter(
            (element) => {
              return (
                element.checkType == "基地抽检" ||
                element.checkType == "市场抽检"
              );
            }
          );
          let [jgcj, lscj] = checkTypeAndNumList;
          let zh = jgcj.checkNum + lscj.checkNum;
          if (item.regionName.length > 3) {
            item.regionName = item.regionName.substring(0, 2);
          }
          let rt = {
            jgcj: jgcj.checkNum,
            lscj: lscj.checkNum,
            zh,
            regionName: item.regionName,
          };
          return rt;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pop-mina-con {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 44px;
    left: 108px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29eaf3;
  }
  .basic-info-con {
    position: absolute;
    top: 118px;
    left: 74px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .bas-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 33px;
        height: 14px;
        margin-right: 5px;
      }
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #07e6ff;
      }
    }
  }
  .detection-con {
    position: absolute;
    left: 50%;
    top: 172px;
    transform: translateX(-50%);
    .cpjcs {
      position: relative;
    }
    .c-mian-con {
      padding: 16px;
      width: 100%;
    }
    .top-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-left {
        width: 931px;
        height: 363px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        margin-right: 20px;
      }
      .top-ri {
        width: 487px;
        height: 363px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;

        .qydys-list {
          margin-top: 15px;

          .title-con {
            padding: 0 20px;
            span {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #18efff;
              text-align: left;
              &:nth-child(1) {
                margin-right: 90px;
              }
              &:nth-child(2) {
                margin-right: 35px;
                width: 64px;
              }
              &:nth-child(3) {
                margin-right: 30px;
                width: 64px;
              }
            }
          }
          .news-list {
            // position: relative;
            width: 100%;
            box-sizing: border-box;
            height: 250px;
            overflow-y: auto;
            .info-row {
              padding: 5px 13px;
              margin-top: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 45px;
              span {
                font-size: 16px;
                font-weight: 400;
                color: #f7fefe;
                &:nth-child(1) {
                  margin-right: 55px;
                  width: 80px;
                }
                &:nth-child(2) {
                  width: 39px;
                  margin-right: 64px;
                }
                &:nth-child(3) {
                  width: 48px;
                  margin-right: 37px;
                  color: #29eaf3;
                }
              }
              &:nth-child(odd) {
                background: #142951;
                border-radius: 5px;
              }
            }
          }
          .news-list::-webkit-scrollbar {
            display: none;
          }
          .swiper-container {
            position: relative;
            height: 100%;
            .swiper-slide {
              position: relative;
              width: 100%;
            }
          }
        }
      }
    }
    .bottom-con {
      width: 1439px;
      height: 364px;
      background-color: rgba($color: #1a3566, $alpha: 0.23);
      border-radius: 20px;
      margin-top: 15px;
      position: relative;
      .tabs {
        position: absolute;
        right: 30px;
        top: 16px;
      }
      .type-list {
        position: absolute;
        right: 30px;
        top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .category {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-right: 22px;
          h1 {
            width: 16px;
            height: 6px;
            border-radius: 5px;
            margin-right: 6px;
          }
          .lbo-bar {
            background-color: #19aefb;
          }
          .lbt-bar {
            background-color: #ef9f14;
          }
          img {
            width: 16px;
            height: 6px;
            margin-right: 6px;
          }
        }
      }
      .chart-con {
        width: 100%;

        padding: 0 10px;
        box-sizing: border-box;
        .line-con {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 74px;
    top: 99px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .bas-r {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    .option-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 18px;

      img {
        height: 11px;
        width: 11px;
        margin-right: 5px;
      }
      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .jtjjc-con {
    display: flex;
  }
}
</style>
